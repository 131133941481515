import Card, { CardSection, CardTitle } from '@pds-react/card';
import '@pds-react/card/dist/card.min.css';
import React from 'react';
import './__CardSample.scss';

type cardPropTypes = {
  onBtnClick?: () => void;
  title: string;
  subTitle: string;
  BtnLabel: string;
  loading?: boolean;
};

const CardSample: React.FC<cardPropTypes> = (props) => {
  const { onBtnClick, title, subTitle, BtnLabel, loading = false } = props;
  return (
    <div className='card'>
      <Card>
        <CardSection>
          <CardTitle>{title}</CardTitle>
          <p className='card__desc'>{subTitle}</p>
          <button className='pds-button pds-button-primary mt-1' onClick={onBtnClick} disabled={loading}>
            {BtnLabel}
          </button>
        </CardSection>
      </Card>
    </div>
  );
};

export default CardSample;
