import Button from '@pds-react/button';
import '@pds/button/button.min.css';
import React from 'react';

type ButtonProps = {
  label: string;
  className?: string;
  onBtnClick?: () => void;
};

const PDSButton: React.FC<ButtonProps> = (props) => {
  const { label, className = '', onBtnClick } = props;
  return (
    <Button iconLeft='settings' className={className} onClick={onBtnClick}>
      {label}
    </Button>
  );
};

export default PDSButton;
