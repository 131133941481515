import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import WelcomePage from '../pages/WelcomePage';
import RequireAuth from '../utils/RequireAuth';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path='/' element={<LoginPage />} />
    <Route path='/home' element={<WelcomePage />} />
    <Route element={<RequireAuth />}>
      <Route path='/logout' element={<LogoutPage />} />
    </Route>
  </Routes>
);

export default AppRoutes;
