import React from 'react';
import PDSButton from '../../components/PDSButton';
import { persistor } from '../../stores';

const LogoutPage: React.FC = () => {
  const onBtnClick = () => {
    persistor.purge();
  };

  return (
    <div className='card'>
      <PDSButton label='Logout' onBtnClick={onBtnClick} />
    </div>
  );
};

export default LogoutPage;
