const generateOAuthUrl = (urlString: string, codeChallenge: string, nonce: string, state: string) => {
  const url = new URL(urlString);
  const params = new URLSearchParams(url.search);

  params.set('code_challenge', codeChallenge);
  params.set('nonce', nonce);
  params.set('state', state);

  const generatedUrl = `${url.origin}${url.pathname}?${params.toString()}`;
  return generatedUrl;
};

export { generateOAuthUrl };
