import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSessionStore } from '../stores/sessionReducer';

const RequireAuth: React.FC = () => {
  const { accessToken } = useSessionStore();

  return accessToken ? <Outlet /> : <Navigate to='/' />;
};

export default RequireAuth;
