import pkceChallenge from 'pkce-challenge';
import React, { useState } from 'react';
import AuthApi from '../../api/AuthApi';
import CardSample from '../../components/Card';
import { useSessionStore } from '../../stores/sessionReducer';
import { generateOAuthUrl } from '../../utils/authUtil';
import cryptoRandomString from 'crypto-random-string';

const LoginPage: React.FC = () => {
  const { setCodeChallenge, setCodeVerifier, setNonce, setState } = useSessionStore();
  const [isLoading, setIsLoading] = useState(false);

  const onBtnClick = async () => {
    setIsLoading(true);
    try {
      const response = await AuthApi.authenticateUser();
      const url = response.data;
      const pkce = pkceChallenge();
      const codeChallenge = pkce?.code_challenge ?? '';
      const codeVerifier = pkce?.code_verifier ?? '';
      const nonce = cryptoRandomString({ length: 10 });
      const state = cryptoRandomString({ length: 10 });

      setCodeChallenge(codeChallenge);
      setCodeVerifier(codeVerifier);
      setNonce(nonce);
      setState(state);
      const ssoLink = generateOAuthUrl(url, codeChallenge, nonce, state);
      window.open(ssoLink, '_self', 'noreferrer');
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <CardSample
      title='Principal Hong Kong'
      subTitle='Welcome to MPF ODS Portal'
      onBtnClick={onBtnClick}
      BtnLabel='Login'
      loading={isLoading}
    />
  );
};

export default LoginPage;
