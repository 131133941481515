import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { PURGE } from 'redux-persist';
import type { RootState } from './';

interface sessionState {
  accessToken: string;
  codeChallenge: string;
  codeVerifier: string;
  nonce: string;
  state: string;
  authorizationCode: string;
}

const initialState: sessionState = {
  accessToken: '',
  codeChallenge: '',
  codeVerifier: '',
  nonce: '',
  state: '',
  authorizationCode: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    reset: (state) => {
      state.accessToken = '';
      state.codeChallenge = '';
      state.codeVerifier = '';
    },
    initialize: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    initCodeChallenge: (state, action: PayloadAction<string>) => {
      state.codeChallenge = action.payload;
    },
    initCodeVerifier: (state, action: PayloadAction<string>) => {
      state.codeVerifier = action.payload;
    },
    initNonce: (state, action: PayloadAction<string>) => {
      state.nonce = action.payload;
    },
    initState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    initAuthorization: (state, action: PayloadAction<string>) => {
      state.authorizationCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

const { reset, initialize, initCodeChallenge, initCodeVerifier, initNonce, initState, initAuthorization } =
  sessionSlice.actions;

export const useSessionStore = () => {
  const state = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();

  return {
    ...state,
    resetSession: () => dispatch(reset()),
    initializeSession: (token: string) => dispatch(initialize(token)),
    setCodeChallenge: (codeChallenge: string) => dispatch(initCodeChallenge(codeChallenge)),
    setCodeVerifier: (codeVerifier: string) => dispatch(initCodeVerifier(codeVerifier)),
    setNonce: (nonce: string) => dispatch(initNonce(nonce)),
    setState: (state: string) => dispatch(initState(state)),
    setAuthorizationCode: (authorizationCode: string) => dispatch(initAuthorization(authorizationCode)),
  };
};

export default sessionSlice.reducer;
