import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://tuvvs0vcgd.execute-api.ap-east-1.amazonaws.com/v1/auth',
});

export default {
  authenticateUser: () =>
    instance({
      url: '/config',
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }),
  authenticateToken: (authorizationCode: string, codeVerifier: string) =>
    instance({
      url: '/token',
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        authorization_code: authorizationCode,
        code_verifier: codeVerifier,
      },
    }),
};
