import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardSample from '../../components/Card';
import { useSessionStore } from '../../stores/sessionReducer';
import Alert from '@pds-react/alert';
import AnimatedIcon from '@pds-react/animatedIcon';
import '@pds-react/alert/dist/alert.min.css';
import '@pds-react/animatedIcon/dist/animatedIcon.min.css';
import AuthApi from '../../api/AuthApi';
import jwt_decode from 'jwt-decode';
import { idTokenProps } from '../../utils/authTokenProps';

const WelcomePage: React.FC = () => {
  const location = useLocation();
  const { setAuthorizationCode, initializeSession, state, nonce, codeVerifier } = useSessionStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const executedRef = useRef(false);

  useEffect(() => {
    if (executedRef.current) {
      return;
    }
    setIsLoading(true);
    const hash = location.hash;
    const searchParams = new URLSearchParams(hash);
    const authorizationCode = searchParams.get('#code') ?? '';
    const stateCode = searchParams.get('state');
    const idToken = searchParams.get('id_token') ?? '';
    try {
      const decodedToken = jwt_decode<idTokenProps>(idToken);
      setName(decodedToken?.name ?? '');
      setEmail(decodedToken?.email ?? '');
      const nonceCode = decodedToken?.nonce ?? '';
      const exp = decodedToken?.exp ?? 0;
      const currentTime = new Date().getTime() / 1000;
      if (stateCode && state === stateCode && nonce === nonceCode && currentTime < exp) {
        setAuthorizationCode(authorizationCode);
        AuthApi.authenticateToken(authorizationCode, codeVerifier)
          .then((response) => {
            const data = JSON.parse(response.data);
            initializeSession(data?.access_token ?? '');
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
    executedRef.current = true;
  }, []);

  const navigateToLogoutPage = () => {
    navigate('/logout');
  };

  return isLoading ? (
    <div className='card'>
      <AnimatedIcon animationType='rotate' aria-label='loading' name='loader' />
    </div>
  ) : isError ? (
    <div className='card'>
      <Alert variant='error' role='alert'>
        Authentication Error
      </Alert>
    </div>
  ) : (
    <CardSample title={`Welcome ${name} !`} subTitle={email} onBtnClick={navigateToLogoutPage} BtnLabel='Next' />
  );
};

export default WelcomePage;
